// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-adopt-js": () => import("./../../../src/pages/adopt.js" /* webpackChunkName: "component---src-pages-adopt-js" */),
  "component---src-pages-contact-js": () => import("./../../../src/pages/contact.js" /* webpackChunkName: "component---src-pages-contact-js" */),
  "component---src-pages-elevage-js": () => import("./../../../src/pages/elevage.js" /* webpackChunkName: "component---src-pages-elevage-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-templates-blog-js": () => import("./../../../src/templates/blog.js" /* webpackChunkName: "component---src-templates-blog-js" */),
  "component---src-templates-portee-js": () => import("./../../../src/templates/portee.js" /* webpackChunkName: "component---src-templates-portee-js" */),
  "component---src-templates-portees-js": () => import("./../../../src/templates/portees.js" /* webpackChunkName: "component---src-templates-portees-js" */),
  "component---src-templates-post-js": () => import("./../../../src/templates/post.js" /* webpackChunkName: "component---src-templates-post-js" */),
  "component---src-templates-race-js": () => import("./../../../src/templates/race.js" /* webpackChunkName: "component---src-templates-race-js" */),
  "component---src-templates-standard-js": () => import("./../../../src/templates/standard.js" /* webpackChunkName: "component---src-templates-standard-js" */)
}

