module.exports = [{
      plugin: require('../node_modules/gatsby-remark-images/gatsby-browser.js'),
      options: {"plugins":[],"maxWidth":800,"linkImagesToOriginal":false},
    },{
      plugin: require('../node_modules/gatsby-plugin-catch-links/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/gatsby-plugin-netlify-cms/gatsby-browser.js'),
      options: {"plugins":[],"modulePath":"/opt/buildhome/repo/src/cms/cms.js","enableIdentityWidget":true,"publicPath":"admin","htmlTitle":"Content Manager"},
    },{
      plugin: require('../node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"name":"Elevage du Cèdre Enchanté","short_name":"Cedrenchante","description":"L'Elevage du Cèdre Enchanté réputé pour sa sélection et son palmarès vous fait découvrir ses chiens (Epagneul Tibétain, Chihuahua, Shetland), chats (Sacré de Birmanie, British Shorthair, Persan)","start_url":"/","background_color":"#f7f5f2","theme_color":"#8AB7AB","display":"minimal-ui","icon":"src/images/favicon.png","legacy":true,"theme_color_in_head":true,"cache_busting_mode":"query","crossOrigin":"anonymous","include_favicon":true,"cacheDigest":"15542ac36cd9c48d8ee375307b5c9332"},
    },{
      plugin: require('../node_modules/gatsby-plugin-intl/gatsby-browser.js'),
      options: {"plugins":[],"path":"/opt/buildhome/repo/src/i18n","languages":["fr","en"],"defaultLanguage":"fr","redirect":false},
    },{
      plugin: require('../gatsby-browser.js'),
      options: {"plugins":[]},
    }]
